import React from "react";
import "./index.scss";

const Section3 = () => (
  <section className="PgAKT-Section3">
    <div className="container">
      <div className="PgAKT-Section3__title">
        Avoid doing manual keyword research
      </div>
      <div className="PgAKT-Section3__text">
        Our tools suggest the best keywords so that you can outsell your
        competition. Get a free trial of the <b>Reverse ASIN Lookup</b>,{" "}
        <b>Amazon Keyword Search</b>, and <b>Keyword Tracker</b> and start
        growing your business!
      </div>
    </div>
  </section>
);

export default Section3;
