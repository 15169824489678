import React from "react"
import Jpg1 from "../../../assets/images/raster/pages/amazon-keyword-tools/Section1/kwTools-section1-1.jpg"
import Jpg1_2x from "../../../assets/images/raster/pages/amazon-keyword-tools/Section1/kwTools-section1-1@2x.jpg"
import Webp1 from "../../../assets/images/raster/pages/amazon-keyword-tools/Section1/kwTools-section1-1.webp"
import Webp1_2x from "../../../assets/images/raster/pages/amazon-keyword-tools/Section1/kwTools-section1-1@2x.webp"

import Jpg2 from "../../../assets/images/raster/pages/amazon-keyword-tools/Section1/kwTools-section1-2.jpg"
import Jpg2_2x from "../../../assets/images/raster/pages/amazon-keyword-tools/Section1/kwTools-section1-2@2x.jpg"
import Webp2 from "../../../assets/images/raster/pages/amazon-keyword-tools/Section1/kwTools-section1-2.webp"
import Webp2_2x from "../../../assets/images/raster/pages/amazon-keyword-tools/Section1/kwTools-section1-2@2x.webp"

import Jpg3 from "../../../assets/images/raster/pages/amazon-keyword-tools/Section1/kwTools-section1-3.jpg"
import Jpg3_2x from "../../../assets/images/raster/pages/amazon-keyword-tools/Section1/kwTools-section1-3@2x.jpg"
import Webp3 from "../../../assets/images/raster/pages/amazon-keyword-tools/Section1/kwTools-section1-3.webp"
import Webp3_2x from "../../../assets/images/raster/pages/amazon-keyword-tools/Section1/kwTools-section1-3@2x.webp"

const PG_AKT_SECTION_1_CONTENT = [
  {
    pic: Jpg1,
    pic2x: Jpg1_2x,
    webp: Webp1,
    webp2x: Webp1_2x,
    text: () => (
      <p>
        Spy on your competitors and discover new keyword ideas for your products
        with the
        <span>Reverse ASIN Lookup</span>
      </p>
    ),
  },
  {
    pic: Jpg2,
    pic2x: Jpg2_2x,
    webp: Webp2,
    webp2x: Webp2_2x,
    text: () => (
      <p>
        Find the related terms that get the highest number of searches with
        <span>Amazon Keyword Search</span>
      </p>
    ),
  },
  {
    pic: Jpg3,
    pic2x: Jpg3_2x,
    webp: Webp3,
    webp2x: Webp3_2x,
    text: () => (
      <p>
        Track your keyword rankings in search results and use your findings to
        optimize your marketing campaigns with the <span>Keyword Tracker</span>
      </p>
    ),
  },
]

export default PG_AKT_SECTION_1_CONTENT
