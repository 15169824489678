import React from "react"
import IntroBg from "../../../assets/images/raster/pages/amazon-keyword-tools/HomeIntro/kwTools-homeIntro.png"
import IntroBg1_5x from "../../../assets/images/raster/pages/amazon-keyword-tools/HomeIntro/kwTools-homeIntro@1_5x.png"
import IntroBgTab from "../../../assets/images/raster/pages/amazon-keyword-tools/HomeIntro/kwTools-homeIntro@tab.png"
import IntroBgTab1_5x from "../../../assets/images/raster/pages/amazon-keyword-tools/HomeIntro/kwTools-homeIntro@tab1_5x.png"
import IntroBgMob from "../../../assets/images/raster/pages/amazon-keyword-tools/HomeIntro/kwTools-homeIntro@mob.png"
import IntroBgMob1_5x from "../../../assets/images/raster/pages/amazon-keyword-tools/HomeIntro/kwTools-homeIntro@mob1_5x.png"
import IntroBgWebp from "../../../assets/images/raster/pages/amazon-keyword-tools/HomeIntro/kwTools-homeIntro.webp"
import IntroBgWebp1_5x from "../../../assets/images/raster/pages/amazon-keyword-tools/HomeIntro/kwTools-homeIntro@1_5x.webp"
import IntroBgTabWebp from "../../../assets/images/raster/pages/amazon-keyword-tools/HomeIntro/kwTools-homeIntro@tab.webp"
import IntroBgTabWebp1_5x from "../../../assets/images/raster/pages/amazon-keyword-tools/HomeIntro/kwTools-homeIntro@tab1_5x.webp"
import IntroBgMobWebp from "../../../assets/images/raster/pages/amazon-keyword-tools/HomeIntro/kwTools-homeIntro@mob.webp"
import IntroBgMobWebp1_5x from "../../../assets/images/raster/pages/amazon-keyword-tools/HomeIntro/kwTools-homeIntro@mob1_5x.webp"
import PointIcon from "../../../assets/images/vector/components/common/group-copy.svg"

const PG_AKT_HOME_INTRO_CONTENT = {
  IntroBg,
  IntroBg1_5x,
  IntroBgTab,
  IntroBgTab1_5x,
  IntroBgMob,
  IntroBgMob1_5x,
  IntroBgWebp,
  IntroBgWebp1_5x,
  IntroBgTabWebp,
  IntroBgTabWebp1_5x,
  IntroBgMobWebp,
  IntroBgMobWebp1_5x,
  PointIcon,
}
export default PG_AKT_HOME_INTRO_CONTENT
