import React from "react";
import { PAGES } from "../../../helpers/constants";
import PG_AKT_HOME_INTRO_CONTENT from "./index.content";
import FormBlockContainer from "../../../containers/common/forms/FormBlockContainer";
import "./index.scss";

const HomeIntro = () => {

  const {
    IntroBg,
    IntroBg1_5x,
    IntroBgTab,
    IntroBgTab1_5x,
    IntroBgMob,
    IntroBgMob1_5x,
    IntroBgWebp,
    IntroBgWebp1_5x,
    IntroBgTabWebp,
    IntroBgTabWebp1_5x,
    IntroBgMobWebp,
    IntroBgMobWebp1_5x,
    PointIcon,
  } = PG_AKT_HOME_INTRO_CONTENT;

  return (
    <section className="PgAKT-HomeIntro">
      <div className="container">
        <div className="PgAKT-HomeIntro-row">
          <div className="PgAKT-HomeIntro-info">
            <h1 className="PgAKT-HomeIntro__h1">
              AMZScout Amazon <span>Keyword Tools</span>
            </h1>
            <div className="PgAKT-HomeIntro-points">
              <div className="PgAKT-HomeIntro-points__item">
                <img src={PointIcon} alt="" />
                <div>Find hundreds of relevant keywords for your products</div>
              </div>
              <div className="PgAKT-HomeIntro-points__item">
                <img src={PointIcon} alt="" />
                <div>
                  Drive customers to your product listings with keywords your
                  competitors don’t know about
                </div>
              </div>
              <div className="PgAKT-HomeIntro-points__item">
                <img src={PointIcon} alt="" />
                <div>
                  Save money on PPC ads by removing phrases that already bring
                  you buyers through Amazon search even without ads
                </div>
              </div>
              <div className="PgAKT-HomeIntro-points__item">
                <img src={PointIcon} alt="" />
                <div>
                  Check monthly search volume for any term to estimate demand
                  for an item
                </div>
              </div>
            </div>
            <FormBlockContainer
              isIntro
              title="Our keyword generators provide you with accurate data for over 3 billion search terms. Get keyword suggestions for a total of 500 million Amazon products."
              buttonTextFirstState="Try AMZScout Tools For Free!"
              buttonTextSecondState="Get amazon keyword tools for FREE"
              page={PAGES.KEYWORD_TOOLS}
              customClass="PgAKT-HomeIntro-emailCollect"
            />
          </div>
          <div className="PgAKT-HomeIntro-screen">
            <picture>
              <source
                type="image/webp"
                media="(min-width: 992px)"
                srcSet={`${IntroBgWebp} 1x, ${IntroBgWebp1_5x} 2x`}
              />
              <source
                media="(min-width: 992px)"
                srcSet={`${IntroBg} 1x, ${IntroBg1_5x} 2x`}
              />
              <source
                type="image/webp"
                media="(min-width: 768px) and (max-width: 992px)"
                srcSet={`${IntroBgTabWebp} 1x, ${IntroBgTabWebp1_5x} 2x`}
              />
              <source
                media="(min-width: 768px) and (max-width: 992px)"
                srcSet={`${IntroBgTab} 1x, ${IntroBgTab1_5x} 2x`}
              />
              <source
                type="image/webp"
                media="(max-width: 767px)"
                srcSet={`${IntroBgMobWebp} 1x, ${IntroBgMobWebp1_5x} 2x`}
              />
              <source
                media="(max-width: 767px)"
                srcSet={`${IntroBgMob} 1x, ${IntroBgMob1_5x} 2x`}
              />
              <img src={IntroBg} alt="" />
            </picture>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeIntro;
