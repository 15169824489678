import React from "react"
import Png1 from "../../../assets/images/raster/pages/amazon-keyword-tools/Section2/kwTools-section2-1.png"
import Png1_2x from "../../../assets/images/raster/pages/amazon-keyword-tools/Section2/kwTools-section2-1@2x.png"
import Png1Mob from "../../../assets/images/raster/pages/amazon-keyword-tools/Section2/kwTools-section2-1@mob.png"
import Png1Mob_2x from "../../../assets/images/raster/pages/amazon-keyword-tools/Section2/kwTools-section2-1@mob2x.png"
import Webp1 from "../../../assets/images/raster/pages/amazon-keyword-tools/Section2/kwTools-section2-1.webp"
import Webp1_2x from "../../../assets/images/raster/pages/amazon-keyword-tools/Section2/kwTools-section2-1@2x.webp"
import Webp1Mob from "../../../assets/images/raster/pages/amazon-keyword-tools/Section2/kwTools-section2-1@mob.webp"
import Webp1Mob_2x from "../../../assets/images/raster/pages/amazon-keyword-tools/Section2/kwTools-section2-1@mob2x.webp"

import Png2 from "../../../assets/images/raster/pages/amazon-keyword-tools/Section2/kwTools-section2-2.png"
import Png2_2x from "../../../assets/images/raster/pages/amazon-keyword-tools/Section2/kwTools-section2-2@2x.png"
import Png2Mob from "../../../assets/images/raster/pages/amazon-keyword-tools/Section2/kwTools-section2-2@mob.png"
import Png2Mob_2x from "../../../assets/images/raster/pages/amazon-keyword-tools/Section2/kwTools-section2-2@mob2x.png"
import Webp2 from "../../../assets/images/raster/pages/amazon-keyword-tools/Section2/kwTools-section2-2.webp"
import Webp2_2x from "../../../assets/images/raster/pages/amazon-keyword-tools/Section2/kwTools-section2-2@2x.webp"
import Webp2Mob from "../../../assets/images/raster/pages/amazon-keyword-tools/Section2/kwTools-section2-2@mob.webp"
import Webp2Mob_2x from "../../../assets/images/raster/pages/amazon-keyword-tools/Section2/kwTools-section2-2@mob2x.webp"

import Png3 from "../../../assets/images/raster/pages/amazon-keyword-tools/Section2/kwTools-section2-3.png"
import Png3_2x from "../../../assets/images/raster/pages/amazon-keyword-tools/Section2/kwTools-section2-3@2x.png"
import Png3Mob from "../../../assets/images/raster/pages/amazon-keyword-tools/Section2/kwTools-section2-3@mob.png"
import Png3Mob_2x from "../../../assets/images/raster/pages/amazon-keyword-tools/Section2/kwTools-section2-3@mob2x.png"
import Webp3 from "../../../assets/images/raster/pages/amazon-keyword-tools/Section2/kwTools-section2-3.webp"
import Webp3_2x from "../../../assets/images/raster/pages/amazon-keyword-tools/Section2/kwTools-section2-3@2x.webp"
import Webp3Mob from "../../../assets/images/raster/pages/amazon-keyword-tools/Section2/kwTools-section2-3@mob.webp"
import Webp3Mob_2x from "../../../assets/images/raster/pages/amazon-keyword-tools/Section2/kwTools-section2-3@mob2x.webp"

const PG_AKT_SECTION_2_CONTENT = [
  {
    pic: Png1,
    pic2x: Png1_2x,
    picMob: Png1Mob,
    picMob2x: Png1Mob_2x,
    webp: Webp1,
    webp2x: Webp1_2x,
    webpMob: Webp1Mob,
    webpMob2x: Webp1Mob_2x,
    title: () => <>Reverse ASIN Lookup</>,
    list: [
      "Enter a competitor’s ASIN to see which keywords they’re targeting to expand your keyword list and get more traffic",
      "Use the relevancy score to know which broad and specific terms are best to add to your PPC campaigns",
      "Determine how well your competitors rank for important keywords",
    ],
  },
  {
    pic: Png2,
    pic2x: Png2_2x,
    picMob: Png2Mob,
    picMob2x: Png2Mob_2x,
    webp: Webp2,
    webp2x: Webp2_2x,
    webpMob: Webp2Mob,
    webpMob2x: Webp2Mob_2x,
    title: () => <>Amazon Keyword Search</>,
    list: [
      "Enter any product or niche and discover which related keywords are the most searched, then use these keywords for your listing",
      "Search keywords related to the niche you are interested in to come up with product ideas that will match popular searches",
      "View the search history for any keyword to find the top consistent and growing search terms to estimate demand",
    ],
  },
  {
    pic: Png3,
    pic2x: Png3_2x,
    picMob: Png3Mob,
    picMob2x: Png3Mob_2x,
    webp: Webp3,
    webp2x: Webp3_2x,
    webpMob: Webp3Mob,
    webpMob2x: Webp3Mob_2x,
    title: () => (
      <>
        Keyword Tracker <span>Chrome Extension</span>
      </>
    ),
    list: [
      "See where your products rank for any search term with advanced tracking software",
      "Make adjustments to your PPC campaigns based on your organic rankings to save money on keywords that don’t need ad support to bring you customers",
      "See where your competitors are ranking in Amazon searches and if you need to use ads to outrank them",
    ],
  },
]

export default PG_AKT_SECTION_2_CONTENT
