import React from "react"
import Jpg1 from "../../../assets/images/raster/pages/amazon-keyword-tools/Section4/kwTools-guides-1.jpg"
import Jpg1_2x from "../../../assets/images/raster/pages/amazon-keyword-tools/Section4/kwTools-guides-1@2x.jpg"
import Webp1 from "../../../assets/images/raster/pages/amazon-keyword-tools/Section4/kwTools-guides-1.webp"
import Webp1_2x from "../../../assets/images/raster/pages/amazon-keyword-tools/Section4/kwTools-guides-1@2x.webp"

import Jpg2 from "../../../assets/images/raster/pages/amazon-keyword-tools/Section4/kwTools-guides-2.jpg"
import Jpg2_2x from "../../../assets/images/raster/pages/amazon-keyword-tools/Section4/kwTools-guides-2@2x.jpg"
import Webp2 from "../../../assets/images/raster/pages/amazon-keyword-tools/Section4/kwTools-guides-2.webp"
import Webp2_2x from "../../../assets/images/raster/pages/amazon-keyword-tools/Section4/kwTools-guides-2@2x.webp"

import Jpg3 from "../../../assets/images/raster/pages/amazon-keyword-tools/Section4/kwTools-guides-3.jpg"
import Jpg3_2x from "../../../assets/images/raster/pages/amazon-keyword-tools/Section4/kwTools-guides-3@2x.jpg"
import Webp3 from "../../../assets/images/raster/pages/amazon-keyword-tools/Section4/kwTools-guides-3.webp"
import Webp3_2x from "../../../assets/images/raster/pages/amazon-keyword-tools/Section4/kwTools-guides-3@2x.webp"

const PG_AKT_SECTION_4_CONTENT = [
  {
    pic: Jpg1,
    pic2x: Jpg1_2x,
    webp: Webp1,
    webp2x: Webp1_2x,
    text: () => (
      <p>
        <span>Amazon SEO</span>
        <span>
          Discover the strategies real sellers use to beat the algorithm and
          rank higher in Amazon’s search index
        </span>
        <span>READ MORE</span>
      </p>
    ),
    url: "/blog/amazon-seo-guide/",
  },
  {
    pic: Jpg2,
    pic2x: Jpg2_2x,
    webp: Webp2,
    webp2x: Webp2_2x,
    text: () => (
      <p>
        <span>Amazon Listing Optimization</span>
        <span>
          Learn tactics that allow you to write better product descriptions, get
          a high number of reviews, and boost conversions
        </span>
        <span>READ MORE</span>
      </p>
    ),
    url: "/blog/amazon-listing-optimization-guidelines/",
  },
  {
    pic: Jpg3,
    pic2x: Jpg3_2x,
    webp: Webp3,
    webp2x: Webp3_2x,
    text: () => (
      <p>
        <span>Amazon Advertising</span>
        <span>
          Find out how to create PPC ads that grow your FBA or wholesale
          business
        </span>
        <span>READ MORE</span>
      </p>
    ),
    url: "/blog/amazon-advertising-beginners-guide/",
  },
]

export default PG_AKT_SECTION_4_CONTENT
