import React from "react";
import PG_AKT_SECTION_4_CONTENT from "./index.content";
import "./index.scss";

const Section4 = () => {
  return (
    <section className="PgAKT-Section4">
      <div className="container">
        <h2 className="PgAKT-Section4__title">Guides</h2>
        <div className="PgAKT-Section4__subtitle">
          Learn how to use your keywords to improve your results and sell more
          products
        </div>
        <div className="PgAKT-Section4-list">
          {PG_AKT_SECTION_4_CONTENT.map(
            ({ pic, pic2x, webp, webp2x, text, url }, index) => (
              <div key={index} className="PgAKT-Section4-item">
                <a href={url}>
                  <div className="PgAKT-Section4-item__img">
                    <picture>
                      <source
                        type="image/webp"
                        srcSet={`${webp} 1x, ${webp2x} 2x`}
                      />
                      <img
                        srcSet={`${pic} 1x, ${pic2x} 2x`}
                        alt=""
                        loading="lazy"
                      />
                    </picture>
                  </div>
                  <div className="PgAKT-Section4-item__text">{text()}</div>
                </a>
              </div>
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default Section4;
