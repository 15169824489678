import React from "react";
import PointIcon from "../../../assets/images/vector/components/common/group-copy.svg";
import PG_AKT_SECTION_2_CONTENT from "./index.content";
import "./index.scss";

const Section2 = () => (
  <section className="PgAKT-Section2">
    <div className="container">
      <h2 className="PgAKT-Section2__title">
        How Keyword Tools Help You Beat Competition
      </h2>
      {PG_AKT_SECTION_2_CONTENT.map(
        (
          {
            pic,
            pic2x,
            webp,
            webp2x,
            picMob,
            picMob2x,
            webpMob,
            webpMob2x,
            list,
            title,
          },
          index
        ) => (
          <div
            key={index}
            className={`PgAKT-Section2-row PgAKT-Section2-row${index + 1}`}
          >
            <div className="PgAKT-Section2-info">
              <div className="PgAKT-Section2-points">
                <div className="PgAKT-Section2-points__title">{title()}</div>
                {list.map((item) => (
                  <div key={item} className="PgAKT-Section2-points__item">
                    <img src={PointIcon} alt="" loading="lazy" />
                    <div>{item}</div>
                  </div>
                ))}
              </div>
            </div>
            <div
              className={`PgAKT-Section2__image PgAKT-Section2__image${
                index + 1
              }`}
            >
              <picture>
                <source
                  type="image/webp"
                  media="(min-width: 768px)"
                  srcSet={`${webp} 1x, ${webp2x} 2x`}
                />
                <source
                  media="(min-width: 768px)"
                  srcSet={`${pic} 1x, ${pic2x} 2x`}
                />
                <source
                  type="image/webp"
                  media="(max-width: 767px)"
                  srcSet={`${webpMob} 1x, ${webpMob2x} 2x`}
                />
                <source
                  media="(max-width: 767px)"
                  srcSet={`${picMob} 1x, ${picMob2x} 2x`}
                />
                <img src={pic} alt="" loading="lazy" />
              </picture>
            </div>
          </div>
        )
      )}
    </div>
  </section>
);

export default Section2;
