import React from "react"
import PNG from "../../../assets/images/raster/components/ProductListings/listing.png"
import PNG_2x from "../../../assets/images/raster/components/ProductListings/listing@2x.png"
import PNG_1200 from "../../../assets/images/raster/components/ProductListings/listing@1200.png"
import PNG_1200_2x from "../../../assets/images/raster/components/ProductListings/listing@1200_2x.png"
import PNG_768 from "../../../assets/images/raster/components/ProductListings/listing@768.png"
import PNG_768_2x from "../../../assets/images/raster/components/ProductListings/listing@768_2x.png"
import PNG_Mob from "../../../assets/images/raster/components/ProductListings/listing@mob.png"
import PNG_Mob_2x from "../../../assets/images/raster/components/ProductListings/listing@mob_2x.png"

import WEBP from "../../../assets/images/raster/components/ProductListings/listing.webp"
import WEBP_2x from "../../../assets/images/raster/components/ProductListings/listing@2x.webp"
import WEBP_1200 from "../../../assets/images/raster/components/ProductListings/listing@1200.webp"
import WEBP_1200_2x from "../../../assets/images/raster/components/ProductListings/listing@1200_2x.webp"
import WEBP_768 from "../../../assets/images/raster/components/ProductListings/listing@768.webp"
import WEBP_768_2x from "../../../assets/images/raster/components/ProductListings/listing@768_2x.webp"
import WEBP_Mob from "../../../assets/images/raster/components/ProductListings/listing@mob.webp"
import WEBP_Mob_2x from "../../../assets/images/raster/components/ProductListings/listing@mob_2x.webp"

const SCREENSHOT_CONTENT = {
    PNG,
    PNG_2x,
    PNG_1200,
    PNG_1200_2x,
    PNG_768,
    PNG_768_2x,
    PNG_Mob,
    PNG_Mob_2x,
    WEBP,
    WEBP_2x,
    WEBP_1200,
    WEBP_1200_2x,
    WEBP_768,
    WEBP_768_2x,
    WEBP_Mob,
    WEBP_Mob_2x,
}
export default SCREENSHOT_CONTENT
