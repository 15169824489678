import React, { useContext, useRef } from "react";
import { MediaAuthContext } from "../store/context/media-auth-context";
import { PAGES } from "../helpers/constants";
import OgImg from "../assets/images/raster/pages/amazon-keyword-tools/mainscreen_keyword_tools.png";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import HomeIntro from "../components/amazon-keyword-tools/HomeIntro";
import FormBlockContainer from "../containers/common/forms/FormBlockContainer";
import CustomizableContainer from "../containers/Paywalls/Customizable/CustomizableContainer";
import Section1 from "../components/amazon-keyword-tools/Section1";
import Section2 from "../components/amazon-keyword-tools/Section2";
import Section3 from "../components/amazon-keyword-tools/Section3";
import Section4 from "../components/amazon-keyword-tools/Section4";
import ProductListings from "../components/amazon-keyword-tools/ProductListings";
import "../components/amazon-keyword-tools/index.scss";

const PgAKT = () => {
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);
  const tariffs = useRef(null);

  return (
    <Layout customClass="PgAKT" tariffsElement={tariffs}>
      <Seo
        title="Amazon Keyword Tools: Comprehensive Keyword Research Toolkit"
        description="Try Amazon keyword research tools by AMZScout. Find the best product keywords, track their rankings and check search volume. Available for free trial."
        page="amazon-keyword-tools"
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <HomeIntro />
      <Section1 />
      <FormBlockContainer
        title="Enter your email to start your FREE TRIAL!"
        customClass={
          isAuth
            ? "PgAKT-FormBlockContainer_secondState"
            : "PgAKT-FormBlockContainer"
        }
        buttonTextFirstState="Try AMZScout For Free!"
        buttonTextSecondState="TRY AMZSCOUT FOR FREE!"
        page={PAGES.KEYWORD_TOOLS}
      />
      <Section2 />
      <Section3 />
      <CustomizableContainer pricingRef={tariffs} customClass="PgAKT-Pricing" />
      <Section4 />
      <ProductListings />
    </Layout>
  );
};

export default PgAKT;
