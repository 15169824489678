import React from "react";
import PG_AKT_SECTION_1_CONTENT from "./index.content";
import "./index.scss";

const Section1 = () => {
  return (
    <section className="PgAKT-Section1">
      <div className="container">
        <h2 className="PgAKT-Section1__title">
          Everything to build attractive listings, run cost-efficient PPC ads
          and beat competitors
        </h2>
        <div className="PgAKT-Section1__subtitle">
          AMZScout offers three keyword finders and tools so that you can
        </div>
        <div className="PgAKT-Section1-list">
          {PG_AKT_SECTION_1_CONTENT.map(
            ({ pic, pic2x, webp, webp2x, text }, index) => (
              <div key={index} className="PgAKT-Section1-item">
                <div className="PgAKT-Section1-item__text">{text()}</div>
                <div className="PgAKT-Section1-item__img">
                  <picture>
                    <source
                      type="image/webp"
                      srcSet={`${webp} 1x, ${webp2x} 2x`}
                    />
                    <img
                      srcSet={`${pic} 1x, ${pic2x} 2x`}
                      alt=""
                      loading="lazy"
                    />
                  </picture>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default Section1;
