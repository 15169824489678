import React from "react";
import TrackedLink from "../../Analytics/TrackedLink";
import SCREENSHOT_CONTENT from "./index.content";
import Screen from "./Screen";
import "./index.scss";

const ProductListings = () => {
  return (
    <section className="PgAKT-PL">
      <div className="container">
        <h2 className="PgAKT-PL__title">
          Get an edge over your competition with our optimized Amazon product
          listings.
        </h2>
        <p className="PgAKT-PL__subtitle">
          Choose a service from Sellerhook by AMZScout for quality and results.
        </p>
        <Screen screenshot={SCREENSHOT_CONTENT} classes="PgAKT-PL-Screen" />
        <p className="PgAKT-PL__description">
          The experienced team of professionals specializes in product and
          keyword research, helping to ensure that your product stands out.
          Whether you plan to launch a new product or optimize an existing one,
          we can provide you with a ready-to-use listing. The experts will help
          you create perfect content using only the most relevant keywords based
          on your criteria to attract customers and improve your sales.
        </p>
        <TrackedLink
          category="AmazonKeywordToolsLP"
          action="SEE DETAILS"
          class="PgAKT-PL__link"
          target
          path="https://listing.sellerhook.com/?utm_source=site&utm_medium=amzscout&utm_campaign=keyword-tools"
        >
          SEE DETAILS
        </TrackedLink>
      </div>
    </section>
  );
};

export default ProductListings;
