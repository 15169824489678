import React from "react";
import PropTypes from "prop-types";

const Screen = ({ classes, screenshot }) => {
  const {
    PNG,
    PNG_2x,
    PNG_1200,
    PNG_1200_2x,
    PNG_768,
    PNG_768_2x,
    PNG_Mob,
    PNG_Mob_2x,
    WEBP,
    WEBP_2x,
    WEBP_1200,
    WEBP_1200_2x,
    WEBP_768,
    WEBP_768_2x,
    WEBP_Mob,
    WEBP_Mob_2x,
  } = screenshot;

  return (
    <div className={classes}>
      <picture>
        <source
          type="image/webp"
          media="(min-width: 1366px)"
          srcSet={`${WEBP}, ${WEBP_2x} 2x`}
        />
        <source media="(min-width: 1366px)" srcSet={`${PNG}, ${PNG_2x} 2x`} />
        <source
          type="image/webp"
          media="(min-width: 992px) and (max-width: 1365px)"
          srcSet={`${WEBP_1200} 1x, ${WEBP_1200_2x} 2x`}
        />
        <source
          media="(min-width: 992px) and (max-width: 1365px)"
          srcSet={`${PNG_1200} 1x, ${PNG_1200_2x} 2x`}
        />
        <source
          type="image/webp"
          media="(min-width: 768px) and (max-width: 991px)"
          srcSet={`${WEBP_768} 1x, ${WEBP_768_2x} 2x`}
        />
        <source
          media="(min-width: 768px) and (max-width: 991px)"
          srcSet={`${PNG_768} 1x, ${PNG_768_2x} 2x`}
        />
        <source
          type="image/webp"
          media="(max-width: 767px)"
          srcSet={`${WEBP_Mob} 1x, ${WEBP_Mob_2x} 2x`}
        />
        <source
          media="(max-width: 767px)"
          srcSet={`${PNG_Mob} 1x, ${PNG_Mob_2x} 2x`}
        />
        <img
          src={PNG}
          className={`${classes}__img`}
          alt="image"
          loading="lazy"
        />
      </picture>
    </div>
  );
};

Screen.propTypes = {
  classes: PropTypes.string,
  screenshot: PropTypes.object,
};

export default Screen;
